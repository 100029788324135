<template>
  <div class="animated reveal">
    <h3 class="mt-12 text-sm font-medium">Active Investment</h3>
    <div
      class="sm:grid inline-flex w-full sm:gap-5 mt-5 sm:grid-cols-3 sm:w-10/12"
    >
      <template v-if="loading">
        <skeleton-loader
          width="250px"
          height="150px"
          class-name="rounded-lg"
          v-for="i in 2"
          :key="i"
        />
      </template>
      <template v-else>
        <template v-if="activeInvestments.length">
          <investment
            v-for="(investment, index) in activeInvestments"
            :key="`investment-${index}`"
            :investment="investment"
            @click="investmentDetails(investment.id)"
          />
          <div class="col-span-1">
            <router-link
              :to="{ name: 'Investment' }"
              class="pt-12 ml-3 sm:ml-0 border btn btn-big"
            >
              <div
                class="
                  flex
                  items-center
                  justify-center
                  w-8
                  h-8
                  rounded-full
                  bg-invest-purple
                "
              >
                <ion-icon name="chevron-forward" class="text-lg text-white" />
              </div>
              <p class="mt-2 text-xs text-invest-dark">View All Investments</p>
            </router-link>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col">
            <p class="mt-3 text-10 text-invest-dark">
              You currently have no active investment.
            </p>

            <button
              type="button"
              class="sm:w-7/12 mt-5 btn btn-dark"
              @click="$refs.createInvestmentModal.open()"
            >
              Start Investing
            </button>
          </div>
        </template>
      </template>
    </div>
  </div>
  <create-investment ref="createInvestmentModal" />
</template>
<script lang="ts">
import { defineComponent, defineAsyncComponent, ref } from 'vue';
import investmentModule from '@/modules/investment/investmentModule';
export default defineComponent({
  components: {
    CreateInvestment: defineAsyncComponent(
      () => import('@/components/investment/create-investment/Index.vue'),
    ),
  },
  setup: () => {
    const createInvestmentModal = ref<any>(null);

    const { loading, activeInvestments, investmentDetails } =
      investmentModule();

    return {
      createInvestmentModal,
      loading,
      activeInvestments,
      investmentDetails,
    };
  },
});
</script>
