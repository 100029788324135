
import { defineComponent, defineAsyncComponent, ref } from 'vue';
import investmentModule from '@/modules/investment/investmentModule';
export default defineComponent({
  components: {
    CreateInvestment: defineAsyncComponent(
      () => import('@/components/investment/create-investment/Index.vue'),
    ),
  },
  setup: () => {
    const createInvestmentModal = ref<any>(null);

    const { loading, activeInvestments, investmentDetails } =
      investmentModule();

    return {
      createInvestmentModal,
      loading,
      activeInvestments,
      investmentDetails,
    };
  },
});
